module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"display":"standalone","name":"Elad Mizrahi's Portfolio","short_name":"Elad Mizrahi","start_url":"/","background_color":"#f7f6fa","theme_color":"#17181b","icon":"src/images/icon.svg","icons":[{"src":"/icons/maskable_icon.png","type":"image/png","sizes":"1024x1024"},{"src":"/icons/maskable_icon_x48.png","sizes":"48x48","type":"image/png","purpose":"maskable"},{"src":"/icons/maskable_icon_x512.png","sizes":"72x72 96x96 128x128 256x256 384x384 512x512","type":"image/png","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7fa6e030f17d603f37e640175e01d4f2"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
